
  import Vue from 'vue';

  export default Vue.extend({
    name: 'SortSelector',
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        sortList: [
          {
            value: '0',
            label: '默认排序',
          },
          {
            value: '1',
            label: '上架时间排序',
          },
          {
            value: '2',
            label: '收藏量排序',
          },
          {
            value: '3',
            label: '下载量排序',
          },
        ],
        sortIndex: 0,
      };
    },
    methods: {
      handleCommand(idx) {
        this.sortIndex = idx;
        this.$emit('input', this.sortList[idx].value);
      },
    },
  });
