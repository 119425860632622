
  import Vue from 'vue';
  import * as api from '@/api';
  import TypeSelectorSingle from '@/components/TypeSelectorSingle.vue';

  export default Vue.extend({
    name: 'AdvancedFilter',
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    components: { TypeSelectorSingle },
    data() {
      return {
        brandList: [] as api.Brand[],
        advanceVisible: false,
        typeSelectorVisible: false,
        filterData: {
          name: '',
          type: null,
          brandId: null as number | null,
          isOffline: null as boolean | null,
          isNew: null as boolean | null,
        },
        typeName: '',
      };
    },
    async created() {
      const res = await api.getBrandList({
        MaxResultCount: 999, // 后端没有获取全部的接口
      });
      if (!api.error(res)) {
        this.brandList = res.data.items;
      }
    },
    methods: {
      onTypeInput(type) {
        if (type) {
          this.filterData.type = type.id;
          this.typeName = type.fullTxt;
        } else {
          this.filterData.type = null;
          this.typeName = '';
        }
        this.typeSelectorVisible = false;
      },
      reset() {
        this.filterData = {
          name: '',
          type: null,
          brandId: null,
          isOffline: null,
          isNew: null,
        };
        this.$emit('input', JSON.parse(JSON.stringify(this.filterData)));
      },
      submit() {
        this.$emit('input', JSON.parse(JSON.stringify(this.filterData)));
      },
    },
  });
