
  import Vue from 'vue';
  import * as api from '@/api';

  export default Vue.extend({
    name: 'TypeSelectorSingle',
    data() {
      return {
        typeTreeData: {} as api.ProductInfoTypeTreeNode[],
        selectedId: null as null | string,
        typeForestData: [] as api.ProductInfoTypeTreeNode[],
      };
    },
    computed: {
      typeMapById(): { [id: number]: api.ProductInfoTypeTreeNode } {
        const ret = Object.create(null);
        function dfs(node: api.ProductInfoTypeTreeNode) {
          ret[node.id] = node;
          node.children.forEach((child) => {
            dfs(child);
          });
        }
        this.typeForestData.forEach((tree) => dfs(tree));
        return ret;
      },
    },
    async created() {
      const res = await api.getProductInfoTypeTree();
      if (!api.error(res)) {
        this.typeForestData = res.data.roots;
      }
    },
    methods: {
      onCurrentChange(data) {
        if (this.selectedId === data.id) {
          (this.$refs.tree as any).setCurrentKey(null);
          this.selectedId = null;
        } else {
          this.selectedId = data.id;
        }
      },
      submit() {
        this.$emit('submit', this.selectedId && this.typeMapById[this.selectedId]);
      },
    },
  });
