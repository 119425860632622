
import Vue from "vue";
import ProductSortSelector from "@/components/ProductSortSelector.vue";
import ProductFilterAdvanced from "@/components/ProductFilterAdvanced.vue";
import * as api from "@/api";
import ProductStatus from "@/components/ProductStatus.vue";

export default Vue.extend({
  name: "ProductList",
  components: {
    ProductSortSelector,
    ProductFilterAdvanced,
    ProductStatus
  },
  data() {
    return {
      tabs: [
        {
          label: "全部产品",
          status: null,
          count: 0
        },
        {
          label: "已通过",
          status: 30,
          count: 0
        },
        {
          label: "审核中",
          status: 10,
          count: 0
        },
        {
          label: "未通过",
          status: 20,
          count: 0
        },
        {
          label: "草稿",
          status: 0,
          count: 0
        }
      ] as { label: string; status: 0 | 10 | 20 | 30 | null; count: number }[],
      currentTabIdx: 0,
      filterVisible: false,
      filterData: {
        name: "",
        type: "",
        brandId: "",
        isOffline: null,
        isNew: null
      },
      auditDetailVisible: false,
      productList: [] as api.Product[],
      pagination: {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
      },
      queryParams: {
        name: "",
        sorting: "0", // 这样父组件就需要关心排序字段的默认值了，不能让子组件填写这个默认值吗？
        status: null,
        skipCount: 0,
        maxResultCount: 10,
        type: null,
        brandId: "",
        isOffline: null,
        isNew: null
      } as api.GetProductListParams,
      selectedProducts: [] as api.Product[],
      loading: false
    };
  },
  watch: {
    queryParams: {
      async handler() {
        await this.fetchData();
      },
      deep: true
    },
    currentTabIdx(idx) {
      this.queryParams.status = this.tabs[idx].status;
    },
    pagination: {
      handler(pagination) {
        this.queryParams.skipCount = (pagination.currentPage - 1) * pagination.pageSize;
        this.queryParams.maxResultCount = pagination.pageSize;
      },
      deep: true
    },
    filterData: {
      handler(data) {
        Object.assign(this.queryParams, data);
      },
      deep: true
    }
  },
  async created() {
    this.$store.commit("breadcrumbList", [
      {
        name: "产品列表",
        path: "/list"
      }
    ]);
    // todo: 筛选条件和路由参数联动
    this.filterData.type = this.$route.query.type as string;
    this.filterData.brandId = this.$route.query.brandId as string;
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const res = await api.getProduct(this.queryParams);
      this.loading = false;
      if (!api.error(res)) {
        this.pagination.totalCount = res.data.totalCount;
        this.productList = res.data.items;
      }
      const cntRes = await api.getProductCount();
      if (!api.error(cntRes)) {
        cntRes.data.forEach(statusCnt => {
          this.tabs.forEach(tab => {
            if (tab.status === statusCnt.status) {
              tab.count = statusCnt.count;
            }
          });
        });
      }
    },
    navToVersionDetail(product: api.Product) {
      this.$router.push({ name: "VersionDetail", params: { id: product.productId } });
    },
    addProduct() {
      this.$store.commit("productInfoModule/selectedClassify", []);
      this.$router.push({ path: "/product/add" });
      this.$store.commit("productInfoModule/currentProductInfo", {});
    },
    editProduct(product) {
      this.$store.commit("productInfoModule/currentProductInfo", {
        productId: product.productId,
        pvId: product.pvId,
        status: product.status,
        statusTxt: product.statusTxt,
        name: product.name,
        nextVersion: product.nextVersion
      });
      this.$router.push({
        path: "/product/edit"
      });
    },
    editNextVersion(product) {
      this.$store.commit("productInfoModule/currentProductInfo", {
        productId: product.productId,
        pvId: product.nextVersionId,
        status: product.nextVersionStatus,
        statusTxt: product.nextVersionStatusTxt
      });
      this.$router.push({
        path: "/product/edit"
      });
    },
    deleteProduct(product: api.Product | api.Product[]) {
      let willDeleteIds;
      if (Array.isArray(product)) {
        willDeleteIds = product.map(p => p.productId);
      } else {
        willDeleteIds = [product.productId];
      }
      this.$confirm("产品删除后，可在左侧列表 [产品回收站] 恢复", "你确定要删除该产品吗？", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定删除",
        confirmButtonClass: "bg-danger",
        cancelButtonText: "取消",
        iconClass: "el-icon-question"
      }).then(async () => {
        const res = await api.deleteProduct(willDeleteIds);
        if (!api.error(res)) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.fetchData();
        } else {
          this.$message({
            message: res.error.message,
            type: "error"
          });
        }
      });
    },
    deleteProductBatch() {
      if (this.selectedProducts.length > 0) {
        this.deleteProduct(this.selectedProducts);
      }
    },
    handleSelectionChange(selection: api.Product[]) {
      this.selectedProducts = selection;
    },
    async toggleProductOffline(products: api.Product[], changeTo: boolean) {
      const productIds = products.map(p => p.productId);
      const res = changeTo
        ? await api.offlineProduct({ productIds })
        : await api.onlineProduct({ productIds });
      if (api.error(res)) {
        this.$message({
          type: "error",
          message: res.error.message
        });
      } else {
        this.$message({
          type: "success",
          message: "操作成功"
        });
        products.forEach(product => {
          product.isOffline = changeTo;
        });
      }
    },
    async toggleProductNew(products: api.Product[], changeTo: boolean) {
      const productIds = products.map(p => p.productId);
      const res = changeTo
        ? await api.setProductToNew({ productIds })
        : await api.setProductToOld({ productIds });
      if (!api.error(res)) {
        this.$message({
          type: "success",
          message: "操作成功"
        });
        products.forEach(product => {
          product.isNew = changeTo;
        });
      } else {
        this.$message({
          type: "error",
          message: res.error.message
        });
      }
    },
    async toggleProductOfflineBatch(changeTo: boolean) {
      await this.toggleProductOffline(this.selectedProducts, changeTo);
    },
    async toggleProductNewBatch(changeTo: boolean) {
      await this.toggleProductNew(this.selectedProducts, changeTo);
    },
    // todo
    changeProductType() {
      console.log("changeProductType");
    },
    changeProductTypeBatch() {
      console.log("changeProductTypeBatch");
    },
    handleBatchCommand(command) {
      if (this.selectedProducts.length === 0) {
        this.$message({
          type: "warning",
          message: "未选中任何产品"
        });
        return;
      }
      switch (command) {
        case "online":
          this.toggleProductOfflineBatch(false);
          break;
        case "offline":
          this.toggleProductOfflineBatch(true);
          break;
        case "new":
          this.toggleProductNewBatch(true);
          break;
        case "old":
          this.toggleProductNewBatch(false);
          break;
        case "changeType":
          this.changeProductTypeBatch();
          break;
        case "delete":
          this.deleteProductBatch();
          break;
        default:
      }
    }
  }
});
